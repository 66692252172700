<template>
  <b-card>
    <b-card-body>
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="12"
          xl="12"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <!-- Media -->
            <b-media>
              <b-col
                cols="12"
                md="4"
                class="text-center mb-1"
              >
                <b-img
                  :src="avatar"
                  text=""
                  variant="light-success"
                  fluid
                  class="border"
                />
              </b-col>
            </b-media>
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h2 class="mb-1 text-primary text-uppercase">
                  <b>{{ elementData.first_name }} {{ elementData.second_name }}</b>
                  <br>
                  <b>{{ elementData.first_surname }} {{ elementData.second_surname }}</b>
                </h2>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="12"
        >
          <h3 class="mb-1 text-primary text-uppercase">
            <b>{{ $t('patient.section.personal_info') }}</b>
          </h3>
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th>
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.documentType') }}</span>
              </th>
              <td>
                {{ getTypeValue(elementData, 'document_type') }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.identity') }}</span>
              </th>
              <td>
                {{ elementData.identity }}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <br>
                <h3 class="mb-1 text-primary text-uppercase">
                  <b>{{ $t('patient.section.origin_data') }}</b>
                </h3>
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.birth_country') }}</span>
              </th>
              <td>
                {{ getTypeValue(elementData, 'birth_country') }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.birthdate') }}</span>
              </th>
              <td>
                {{ getDate(elementData, 'birthdate') }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.age') }}</span>
              </th>
              <td>
                {{ getAge(elementData, 'birthdate') }}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <br>
                <h3 class="mb-1 text-primary text-uppercase">
                  <b>{{ $t('patient.section.demographic_data') }}</b>
                </h3>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.sex') }}</span>
              </th>
              <td class="pb-50">
                {{ getTypeValue(elementData, 'sex') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.genre') }}</span>
              </th>
              <td class="pb-50">
                {{ getTypeValue(elementData, 'genre') }}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <br>
                <h3 class="mb-1 text-primary text-uppercase">
                  <b>{{ $t('patient.section.job_data') }}</b>
                </h3>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.employment') }}</span>
              </th>
              <td class="pb-50">
                {{ getTypeValue(elementData, 'employment') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.eapb_eps') }}</span>
              </th>
              <td class="pb-50">
                {{ getTypeValue(elementData, 'eapb_eps') }}
              </td>
            </tr>

          </table>
          <br><br>
          <h3 class="mb-1 text-primary text-uppercase">
            <b>{{ $t('contact.section.contact_data') }}</b>
          </h3>
          <div>
            <b-table
              striped
              hover
              :items="elementData.contacts"
              :fields="contact_data_fields"
            />
          </div>
          <br><br>
          <h3 class="mb-1 text-primary text-uppercase">
            <b>{{ $t('guardian.section.guardian_data') }}</b>
          </h3>
          <div>
            <b-table
              striped
              hover
              :items="elementData.guardians"
              :fields="guardian_data_fields"
            />
          </div>

        </b-col>
      </b-row>
      <br><br>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge, BTable, BMedia, BImg,
} from 'bootstrap-vue'
import moment from 'moment'

import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BCardHeader, BCardBody, BTable, BMedia, BImg,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    elementName: {
      type: String,
      required: true,
    },
    elementAvatar: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      avatar: null,
      contact_data_fields: [
        {
          key: 'relationship.value',
          label: 'parentesco',
        },
        {
          key: 'type.value',
          label: 'Tipo',
        },
        {
          key: 'data',
          label: 'Información',
        },
        {
          key: 'status',
          label: 'Estado',
          formatter: (value) => {
            return this.resolveElementStatusMessage(value)
          }
        },
        {
          key: 'department.value',
          label: 'Departamento',
        },
        {
          key: 'city.value',
          label: 'Ciudad',
        },
      ],
      guardian_data_fields: [
        {
          key: 'relationship.value',
          label: 'parentesco',
        },
        {
          key: 'name',
          label: 'Nombre',
        },
        {
          key: 'identity',
          label: 'Documento',
        },
      ],
    }
  },
  watch: {
    elementAvatar(newValue, oldValue) {
      this.avatar = newValue
    },
  },
  setup() {
    return {
      avatarText,
      getTypeValue(element, type) {
        return element[type] ? element[type].value : ''
      },
      getDate(element, type) {
        if (element[type]) {
          let date = element[type]
          if (!moment.isMoment(date)) {
            date = moment(date, 'DD/MM/YYYYTHH:mm:ss')
          }
          return date.format('DD/MM/YYYY')
        }
        return null
      },
      getAge(element, type) {
        if (element[type]) {
          let date = element[type]
          if (!moment.isMoment(date)) {
            date = moment(date, 'DD/MM/YYYYTHH:mm:ss')
          }
          return moment().diff(date, 'years')
        }
        return null
      },
      resolveElementStatusVariant(status) {
        if (status === 0) return 'warning'
        return 'success'
      },
      resolveElementStatusMessage(status) {
        if (status === 0) return this.$t(`${this.elementName}.status.inactive`)
        return this.$t(`${this.elementName}.status.active`)
      },
    }
  },
}
</script>

<style>

</style>
