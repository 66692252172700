<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0">
      <div class="m-2">
        <h2 class="mb-1 text-primary">
          {{ $t(RESOURCES_ELEMENT_NAME + '.search.title') }} 
        </h2>
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('table.search.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('table.search.entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-start mb-1 mb-md-0">
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              :placeholder="$t('table.search.search')" />
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refElementsListTable"
        class="position-relative"
        :items="formattedItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')">
        <!-- column: name -->
        <template #cell(arl)="data">
          {{ data.item.arl }}
        </template>

        <template #cell(actions)="data">
          <b-col class="flex flex-row flex-wrap">
            <a
              :id="`download-button-${data.item.id}`"
              class="btn btn-primary mb-1 mb-sm-0 mr-0 mr-sm-1"
              :href="getDownloadUrl(data.item.id)"
              target="_blank">
              <feather-icon icon="DownloadIcon" />
            </a>
            <b-button
              :id="`view-button-${data.item.id}`"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="primary"
              :to="{
                name: 'apps-billing-invoice-detail',
                params: { id: data.item.invoice_id },
              }">
              <feather-icon icon="EyeIcon" />
            </b-button>
            <!-- ? DOWNLOAD -->
            <b-tooltip
              :target="`download-button-${data.item.id}`"
              :title="$t('supportDocument.actions.download')"
              triggers="hover"
              :noninteractive="true"
              fallback-placement="counterclockwise">
            </b-tooltip>
            <!-- ? DETAILS -->
            <b-tooltip
              :target="`view-button-${data.item.id}`"
              :title="$t('supportDocument.actions.view')"
              triggers="hover"
              :noninteractive="true"
              fallback-placement="counterclockwise">
            </b-tooltip>
          </b-col>
        </template>
      </b-table>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18" />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
        <a
          id="view-report-button"
          class="btn btn-primary"
          :href="getFullDownloadUrl()"
          target="_blank">
          <span class="text-nowrap">{{
            $t('supportDocument.actions.downloadAll')
          }}</span>
        </a>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import moment from 'moment'
import router from '@/router'
import ElementStoreModule from '../storeModule'

const SIZE_LIST = [50, 100]

export default {
  components: {
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      formattedItems: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      selected_element: {},
      currentSelection: {},
      tableColumns: [],
    }
  },
  watch: {
    perPage(val) {
      this.fetchInvoices()
    },
    currentPage(val) {
      this.fetchInvoices()
    },
    query(val) {
      this.currentPage = 1
      this.fetchInvoices()
    },
  },
  created() {
    this.RESOURCES_ELEMENT_NAME = 'supportDocument'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-${this.RESOURCES_ELEMENT_NAME}`
  },
  async mounted() {
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )

    this.fetchInvoices()

    this.tableColumns = [
      {
        key: 'file_name',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.number`),
        sortable: true,
      },
      {
        key: 'arl',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.arl`),
        sortable: true,
      },
      {
        key: 'service_type',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.service_type`),
        sortable: true,
      },
      {
        key: 'extension',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.extension`),
        sortable: true,
      },
      {
        key: 'date',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.date`),
        sortable: true,
      },
      {
        key: 'due_date',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.due_date`),
        sortable: true,
      },
      {
        key: 'status',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.status`),
        sortable: true,
      },
      {
        key: 'size',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.size`),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.actions`),
        thStyle: 'width: 300px;',
      },
    ]
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    getDownloadUrl(id) {
      return `${process.env.VUE_APP_BASE_API}/api/v1/billing/invoice_support/${id}`
    },
    getFullDownloadUrl() {
      return `${process.env.VUE_APP_BASE_API}/api/v1/billing/invoice_support/full/${router.currentRoute.params.case_id}`
    },
    fetchInvoices() {
      store
        .dispatch(
          `${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchInvoiceSupports`,
          {
            queryParams: {
              perPage: this.perPage,
              page: this.currentPage,
              query: this.query,
            },
            case_id: router.currentRoute.params.case_id,
          }
        )
        .then((response) => {
          this.items = response.data.items
          this.total = response.data.total

          this.formattedItems = this.items.map((item) => ({
            ...item,
            size: `${parseFloat(item.size).toFixed(2)} MB`,
            date: moment(item.date).format('DD/MM/YYYY'),
            due_date: moment(item.due_date).format('DD/MM/YYYY'),
            service_type: item.service_type === 'invoice' ? 'Factura' : item.service_type,
          }))

          console.info('this.items:', this.items)
        })
        .catch(() => {
          console.error('Error fetching elements')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
